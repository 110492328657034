import DroppableController from "./droppable_controller"

export default class extends DroppableController {
  static targets = ["form"]

  connect() {
    super.connect();
    this.actionTemplate = this.formTarget.action;
  }

  handleDrop(projectNumber) {
    document.querySelectorAll('[data-controller="projects-column"]').forEach((element) => {
      element.classList.remove('projects-column--dropzone');
    })
    this.formTarget.action = this.actionTemplate.replace(":number", projectNumber);
    this.formTarget.method = "PATCH";
    this.formTarget.requestSubmit();
  }
}
