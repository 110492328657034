import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.element.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        Turbo.visit(this.urlValue)
      }
    })
  }
}