import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "knr",
    "search",
    "ac",
  ]

  connect() {
    this.disableAutocomplete = false;

    this.acList = document.createElement('ul');
    this.acList.style = 'position: absolute; top: 100%; left: 0; right: 0; z-index: 1000; background-color: white; border: 1px solid #ccc; list-style: none; padding: 0; margin: 0;'
    this.acList.style.display = 'none'
    this.acTarget.appendChild(this.acList)

    this.focusedIndex = -1;
    this.searchTarget.addEventListener('keydown', (event) => {
      const indexWas = this.focusedIndex;
      switch (event.keyCode) {
        // Arrow down
        case 40:
          event.preventDefault();
          this.focusedIndex = Math.min(this.focusedIndex + 1, this.acList.children.length - 1);
          break;
        // Arrow up
        case 38:
          event.preventDefault();
          this.focusedIndex = Math.max(this.focusedIndex - 1, 0);
          break;
        // Enter
        case 13:
          event.preventDefault();
          if (this.focusedIndex >= 0) {
            this.acList.children[this.focusedIndex].click();
          }
          break;
      }
      if (this.focusedIndex === indexWas) return true;

      let index = 0;
      for (const li of this.acList.children) {
        if (index === this.focusedIndex) {
          li.style.backgroundColor = '#f2f2f2';
        } else {
          li.style.backgroundColor = 'white';
        }
        index++;
      }
    });

    this.searchTarget.addEventListener('input', (event) => {
      if (event.inputType === 'insertReplacementText') return;

      const knr = this.knrTarget.value
      const address = this.searchTarget.value
      if (!address || !knr) {
        this.acList.innerHTML = '';
        return true
      }

      this.performAutocomplete(knr, address);

      return true
    })
  }

  /**
   * @param {string} kommunenummer
   * @param {string} address
   */
  performAutocomplete(kommunenummer, address) {
    if (this.disableAutocomplete) return;

    const startTime = performance.now();
    const url = new URL("https://rk-ac-matrikkel-acc057b53424.herokuapp.com/autocomplete");
    url.search = new URLSearchParams({ kommunenummer, prefix: address }).toString();
    fetch(url, {
      headers: {
        "accept": "application/json",
      },
    })
      .then(response => {
        if (response.status !== 200) throw new Error('Failed to fetch autocomplete');
        return response.json()
      })
      .then(data => {
        this.acList.innerHTML = '';
        this.acList.style.display = 'none';
        data.forEach((adressetekst, index) => {
          if (adressetekst.toLowerCase() === address.toLowerCase() && data.length === 1) return;
          const li = document.createElement('li');
          li.textContent = adressetekst;
          if (index > 0) {
            li.style.borderTop = '1px solid #ccc';
          }
          li.style.padding = '0.25rem 0.75rem';
          li.style.cursor = 'pointer';
          li.addEventListener('click', () => {
            this.searchTarget.value = adressetekst;
            const event = new InputEvent('input', { bubbles: true, inputType: 'insertReplacementText' });
            this.searchTarget.dispatchEvent(event);
            this.acList.innerHTML = '';
            this.acList.style.display = 'none';
          });
          this.acList.appendChild(li);
        });
        if (this.acList.children.length > 0) {
          this.acList.style.display = 'block';
        } else {
          this.acList.style.display = 'none';
        }
        console.log(`Autocomplete took ${performance.now() - startTime} ms`);
      })
      .catch(() => {
        this.disableAutocomplete = true;
      });
  }
}
