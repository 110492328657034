import { Controller } from "@hotwired/stimulus"

export default class DroppableController extends Controller {
  connect() {
    this.element.addEventListener("dragover", (event) => {
      // prevent default to allow drop event
      event.preventDefault();
      this.element.classList.add("dropzone--over");
    });
    this.element.addEventListener("dragleave", () => {
      this.element.classList.remove("dropzone--over");
    });
    this.element.addEventListener("drop", (ev) => {
      this.handleDrop(ev.dataTransfer.getData("text/plain"));
    });
  }
  }
