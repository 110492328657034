import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    data: String
  }

  connect() {
    this.element.addEventListener("dragstart", (ev) => {
      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.setData("text/plain", this.dataValue);
      document.querySelectorAll('.projects-column').forEach((element) => {
        setTimeout(() => {
          element.classList.add('projects-column--dropzone');
        }, 1)
      })
    })
    this.element.addEventListener("dragend", () => {
      document.querySelectorAll('[data-controller="projects-column"]').forEach((element) => {
        setTimeout(() => {
          element.classList.remove('projects-column--dropzone');
        }, 1)
      })
    })
  }
}
