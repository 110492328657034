import { Controller } from "@hotwired/stimulus";

export default class TinymceController extends Controller {
  static targets = [
    "editor",
    "content"
  ]

  tinymce = null;

  async editorTargetConnected(element) {
    if (element.classList.contains('ql-container')) return;

    this.tinymce = (await tinymce.init({
      target: element,
      language: 'nb_NO',
      branding: false,
      promotion: false,
      statusbar: false,
      plugins: [
        'lists',
        'link',
        'table',
        'advlist',
        'autoresize',
        'autolink',
        'wordcount'
      ],
      toolbar: 'undo redo | bold italic underline | numlist bullist | table | link',
    }))[0]
  }

  contentTargetConnected(element) {
    this.getForm().addEventListener('submit', (event) => {
      element.value = this.tinymce?.getContent()
    })
  }

  getForm() {
    return this.element;
  }
}
