import { Controller } from "@hotwired/stimulus"

// Debounce function
function debounce(func, wait, immediate) {
  let timeout
  return function() {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default class extends Controller {
  static values = {
    debounce: Number
  }

  connect() {
    let handleChange = () => {
      this.element.requestSubmit()
    };

    if (this.debounceValue) {
      handleChange = debounce(handleChange, this.debounceValue)
    }

    this.element.addEventListener("change", handleChange)

    for (const element of this.element.elements) {
      if (element.type === "search") {
        element.addEventListener("input", handleChange)
      }
    }
  }
}
