import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectAll", "checkbox"];


  toggleAll(event) {
    const checked = event.target.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }

  checkboxChanged() {
    const checkboxCount = this.checkboxTargets.length
    const checkedCount = this.checkboxTargets.filter((checkbox) => checkbox.checked).length
    if (checkedCount === checkboxCount) {
      this.selectAllTarget.indeterminate = false;
      this.selectAllTarget.checked = true;
    } else if (checkedCount === 0) {
      this.selectAllTarget.indeterminate = false;
      this.selectAllTarget.checked = false;
    } else {
      this.selectAllTarget.indeterminate = true;
    }
  }
}
