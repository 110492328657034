import { Controller } from "@hotwired/stimulus"

export default class LoadingLinkController extends Controller {
  connect() {
    this.element.addEventListener("click", this.handleClick.bind(this))
  }

  handleClick() {
    if (this.element.classList.contains("btn--loading")) return;

    this.showLoading()
    const interval = setInterval(() => {
      if (this.downloadCompleted()) {
        clearInterval(interval)
        this.hideLoading()
        document.cookie = "last_download=;Path=/";
      }
    }, 100)
  }

  showLoading() {
    this.element.classList.add("btn--loading")
  }

  hideLoading() {
    this.element.classList.remove("btn--loading")
  }

  downloadCompleted() {
    const cookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("last_download="));
    if (!cookie) return false;
    return !!cookie.split("=")[1];
  }
}
