import { Controller } from "@hotwired/stimulus"

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

export default class extends Controller {
  static targets = [
    "knr",
    "gnr",
    "bnr",
    "search",
    "zip",
    "postalArea",
  ]

  connect() {
    const handleCheck = debounce(this.check.bind(this), 500)
    this.searchTarget.addEventListener("input", handleCheck)
    this.knrTarget.addEventListener("input", handleCheck)

    this.gnrTarget.placeholder = ""
    this.bnrTarget.placeholder = ""

    this.disableAutofill(this.searchTarget)
  }
  /**
   * @param {HTMLInputElement} input
   */
  disableAutofill(input) {
    input.autocomplete = "off"
    input.type = 'search'
    const shadowInput = document.createElement('input')
    shadowInput.type = 'hidden'
    shadowInput.name = input.name
    input.name = '__search__'
    input.after(shadowInput)
    input.addEventListener('input', () => {
      shadowInput.value = input.value
    })
    this.shadowInput = shadowInput
  }

  async check() {
    this.resetBorders();

    const knr = this.knrTarget.value
    const address = this.searchTarget.value
    if (!address || !knr) {
      this.gnrTarget.value = null;
      this.gnrTarget.tabIndex = null;
      this.bnrTarget.value = null;
      this.bnrTarget.tabIndex = null;
      return
    }

    const params = {
      adressetekst: address,
      kommunenummer: knr,
    }
    const url = new URL('/matrikkel_lookup', document.location);
    url.search = new URLSearchParams(params).toString();
    const response = await fetch(url, {
      headers: {
        "accept": "application/json",
      },
    })

    if (response.status !== 200) {
      this.gnrTarget.readOnly = false;
      this.gnrTarget.placeholder = 'Fyll inn manuelt'
      this.bnrTarget.readOnly = false;
      this.bnrTarget.placeholder = 'Fyll inn manuelt'
      return
    }
    this.gnrTarget.readOnly = true;
    this.gnrTarget.placeholder = ''
    this.bnrTarget.readOnly = true;
    this.bnrTarget.placeholder = ''
    const data = await response.json()

    const borderClass = data.metadata.totaltAntallTreff === 1 ? 'border-success' : 'border-warning';
    this.searchTarget.classList.add(borderClass);

    if (data.metadata.totaltAntallTreff !== 1) return
    this.setAddressValue(data.adresser[0].adressetekst);

    this.gnrTarget.value = data.adresser[0].gardsnummer;
    this.gnrTarget.tabIndex = -1;
    this.bnrTarget.value = data.adresser[0].bruksnummer;
    this.bnrTarget.tabIndex = -1;
    this.zipTarget.value = data.adresser[0].postnummer;
    this.postalAreaTarget.value = data.adresser[0].poststed.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
  }

  /**
   * @param {string} addressetekst
   */
  setAddressValue(addressetekst) {
    this.searchTarget.value = addressetekst;
    this.shadowInput.value = addressetekst;
  }

  resetBorders() {
    this.searchTarget.classList.remove('border-success', 'border-warning');
  }
}
